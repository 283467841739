export const referralBaseUrl = `${window.location.hostname.replace('admin.',"")}/register?campaignId=`;

export interface CampaignInfoType {
    affiliateId: string;
    houseId: string;
    commissionPercentage: string;
    affiliatePath: string;
    accountPath: string;
    accountId: string;
    userName: string;
    lifeTimeFTDAmount: string;
    lifeTimeFirstDeposits: string;
    lifeTimeSignUps: string;
    totalCommission: string;
    availableCommission: string;
    campaignCode: string;
    campaignName: string;
    totalDepositAmount: number;
    totalDeposits: number;
}

export interface CampaignInfoDataType {
    userName: string | null;
    lifeTimeFTDAmount: number;
    lifeTimeFirstDeposits: number;
    lifeTimeSignUps: number;
    totalCommission: number;
    availableCommission: number;
    isCampaignCreated: boolean;
    totalDepositAmount: number;
    totalDeposits: number;
    campaignDetailsHashMap: { [key: string]: any };
}

export const initialCampaignDetails = {
    userName: '',
    lifeTimeFTDAmount: 0,
    lifeTimeFirstDeposits: 0,
    lifeTimeSignUps: 0,
    totalCommission: 0,
    availableCommission: 0,
    isCampaignCreated: false,
    totalDepositAmount: 0,
    totalDeposits: 0,
    campaignDetailsHashMap: {},
};

export enum FieldTypes {
    AFFILIATE_NAME = 'affiliateName',
    AFFILIATE_ID = 'affiliateId',
    REFERRAL_LINK = 'referralLink',
}
