import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonSpinner } from '@ionic/react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RefreshIcon from '@material-ui/icons/RefreshOutlined';
import './ChangePassword.scss';
import { logout } from '../../store';
import { AuthResponse } from '../../models/api/AuthResponse';
import { IonLabel } from '@ionic/react';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import API_V2 from '../../svls-api';
import { useHistory } from 'react-router';
import { generateRandomPass, handleTextCopy } from '../../util/stringUtil';
import { toast } from 'react-toastify';

type ChangePwdProps = {
  logout: Function;
  showTermsCondi?: boolean;
  closeHandler: () => void;
  backHandler: () => void;
};

type ChangePasswordRequest = {
  oldPassword: any;
  newPassword: string;
};

const ChangePwdForm: React.FC<ChangePwdProps> = (props) => {
  const { logout, closeHandler, showTermsCondi } = props;
  const [progress, setProgress] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(null);
  const [successMsg, setSuccessMsg] = useState<string>(null);
  const history = useHistory();
  const [isRandomPassGenerated, setRandomPassGenerated] = useState(false);

  const formik = useFormik({
    initialValues: {
      oldPwd: '',
      newPwd: '',
      acceptTerms: false,
    },
    validationSchema: Yup.object({
      oldPwd: Yup.string().required('Required'),
      newPwd: Yup.string()
        .required('Required')
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,}$/,
          'Please choose a strong password. For more details hover on help icon(?).'
        ),
      acceptTerms: showTermsCondi
        ? Yup.bool().test(
            'pointsType',
            'Please accept terms and conditions',
            () => {
              return formik.values.acceptTerms;
            }
          )
        : Yup.string().optional(),
    }),
    onSubmit: (values) => {
      setErrorMsg(null);
      setSuccessMsg(null);
      const data: ChangePasswordRequest = {
        oldPassword: values.oldPwd.trim(),
        newPassword: values.newPwd.trim(),
      };
      if (values.newPwd === values.oldPwd) {
        setErrorMsg('New password should not be same as username/old password');
      } else {
        updateNewPassword(data);
      }
    },
  });

  const updateNewPassword = async (data: ChangePasswordRequest) => {
    try {
      setProgress(true);
      const userName = sessionStorage.getItem('username');
      const response: AuthResponse = await API_V2.put(
        `/account/v2/users/${userName}/password:change`,
        data,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 204) {
        setSuccessMsg('Password changed successfully.');
        logout();
        setTimeout(() => {
          closeHandler();
        }, 2000);
      }
    } catch (err) {
      setErrorMsg(err?.response?.data?.message);
    } finally {
      setProgress(false);
    }
  };

  const setRandomPass = (disableSuccessMsg = false) => {
    setRandomPassGenerated(true);
    const randomPass = generateRandomPass(false);
    formik.setFieldValue('newPwd', randomPass);
    if (!disableSuccessMsg)
      toast.success('New password generated successfully');
  };

  const copyGeneratedPass = () => {
    handleTextCopy(formik.values.newPwd);
    toast.success('Password copied successfully');
  };

  const handleRadomPassGenerationClick = () => {
    isRandomPassGenerated ? copyGeneratedPass() : setRandomPass();
  };

  return (
    <div className="change-password-form-ctn fullWidth">
      <form
        onSubmit={formik.handleSubmit}
        className="change-pwd-form-ctn"
        autoComplete="off"
        id="change-pwd-form"
      >
        <span className="usr-input password-label-ctn">
          <IonLabel className="input-label">Enter old password</IonLabel>
          <TextField
            className="login-input-field user-name"
            type="password"
            name="oldPwd"
            variant="outlined"
            error={formik.touched.oldPwd && formik.errors.oldPwd ? true : false}
            helperText={
              formik.touched.oldPwd && formik.errors.oldPwd
                ? formik.errors.oldPwd
                : null
            }
            {...formik.getFieldProps('oldPwd')}
          />
        </span>

        <div className="pwd-input password-label-ctn">
          <IonLabel className="input-label new-password-ctn">
            <div className="padding-to-3 "> New Password</div>
            <Tooltip
              className="input-tooltip"
              title="Password must contains at least 8 characters and at most 20 characters (It contains at least One Uppercase, One Lowercase and One Number)"
            >
              <HelpOutlineIcon />
            </Tooltip>
          </IonLabel>
          <FormControl
            className="login-input-field pwd-field"
            variant="outlined"
            error={formik.touched.newPwd && formik.errors.newPwd ? true : false}
          >
            <div className="password-input-ctn">
              <OutlinedInput
                className="password-input-field"
                id="standard-adornment-password"
                name="newPwd"
                {...formik.getFieldProps('newPwd')}
                endAdornment={
                  isRandomPassGenerated ? (
                    <span
                      className="password-refresh-icon-ctn"
                      onClick={() => setRandomPass()}
                    >
                      <RefreshIcon className="password-refresh-icon" />
                    </span>
                  ) : null
                }
              />
              <Button
                className="generate-pass-btn"
                color="primary"
                variant="contained"
                onClick={handleRadomPassGenerationClick}
              >
                {!isRandomPassGenerated ? 'Generate' : 'Copy'}
              </Button>
            </div>
            {formik.touched.newPwd && formik.errors.newPwd ? (
              <FormHelperText error id="my-helper-text">
                {formik.errors.newPwd}
              </FormHelperText>
            ) : null}
          </FormControl>
        </div>

        {showTermsCondi ? (
          <div className="accept-terms-input password-label-ctn">
            <FormControlLabel
              className="accept-terms-field password-label-ctn"
              control={
                <Checkbox
                  checked={formik.values.acceptTerms}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  className="accept-terms-checkbox"
                  name="acceptTerms"
                  color="default"
                />
              }
              label="I understand & accept the terms and conditions"
              labelPlacement="end"
            />
            {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
              <FormHelperText id="my-helper-text" className="my-helper-text">
                {formik.errors.acceptTerms}
              </FormHelperText>
            ) : null}
          </div>
        ) : null}

        {errorMsg !== '' ? <span className="error-msg">{errorMsg}</span> : null}
        {successMsg !== '' ? (
          <span className="success-msg">{successMsg}</span>
        ) : null}
        <Button
          className="submit-form-btn-style"
          form="change-pwd-form"
          color="primary"
          endIcon={progress ? <IonSpinner name="lines-small" /> : ''}
          type="submit"
          variant="contained"
          fullWidth
        >
          Change
        </Button>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(ChangePwdForm);
